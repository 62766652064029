.App {
    background-color: black;
    font-family: 'Bona Nova', sans-serif;
}

.header {
    font-size: 2.6rem;
    font-family: 'Bona Nova', Times, serif;
    color: white;
    text-align: center;
    padding-top: 1rem;
}


.navbar-custom{
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;
}

.hero-text {
    background-color:rgba(217, 217, 217, 0.65);
    color: black;
    z-index: 3;
    font-size: 20px;
    width: 50%; 
    padding-left: 1rem;


}

.heroImage {
    z-index: -1;
    width: 100%;
}

.newArrivalCard {
    border-radius: 0%;
    text-underline-offset: 15px;
}

.nav-item .dropdown-menu {
  background: #000000;
}

.nav-item .dropdown-item {
  color: #ffffff;
}

.nav-item .dropdown-item:hover {
  background: #424141;
}

.navbar .nav-link {
  color: white
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}



.offcanvas.offcanvas-start {
  right: 0;
  bottom: initial;
  height: fit-content;
  font-size: 2rem;
  background-color: gray;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
  
}

.adminpanel {
  background-color: white;
}

.whitTxt{
  color:#fff !important;
  filter: none !important;
}


.btn-close-white {
color: #999;
filter: none !important;
}
.btn-close{
  filter: invert(1) grayscale(100%) brightness(200%);
}

.video {
  height: 20rem;
}

@media (max-width: 500px) {
  .hover-dropdown .container {
    transform: none;
  }
}
